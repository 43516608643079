.pacientes-container {
  width: 100%;
  height: 100vh;
  padding: 2rem;
  background: #1a1a1a;
  overflow-y: auto;
  box-sizing: border-box;
}

.search-section, .add-patient-section {
  background: linear-gradient(145deg, #222222, #2d2d2d);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.section-title {
  color: #4a90e2;
  margin: 0 0 1.5rem 0;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: 2px solid rgba(74, 144, 226, 0.3);
  padding-bottom: 0.5rem;
}

.patient-info {
  background: rgba(42, 42, 42, 0.6);
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  color: #ffffff;
}

.patient-info p {
  margin: 0.5rem 0;
  display: flex;
  gap: 0.5rem;
}

.patient-info strong {
  color: #4a90e2;
  min-width: 120px;
}

.patient-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.info-item {
  background: rgba(50, 50, 50, 0.4);
  border-radius: 8px;
  padding: 0.8rem 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.highlight-item {
  background: rgba(74, 144, 226, 0.15);
  border: 1px solid rgba(74, 144, 226, 0.3);
}

.highlight-item .info-label {
  color: #5a9be6;
}

.info-item:hover {
  background: rgba(60, 60, 60, 0.4);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.address-info {
  grid-column: span 2;
}

.info-label {
  color: #4a90e2;
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
}

.info-value {
  font-size: 1rem;
  color: #ffffff;
  word-break: break-word;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.2rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  color: #4a90e2;
  font-size: 0.9rem;
  font-weight: 500;
}

.form-input {
  padding: 0.8rem;
  background: #333;
  color: white;
  border: 1px solid #444;
  border-radius: 6px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.form-input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

select.form-input {
  cursor: pointer;
}

.submit-button {
  padding: 0.75rem 1.75rem;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background: linear-gradient(135deg, #4a90e2, #357abd);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.submit-button:hover:not(:disabled) {
  background: linear-gradient(135deg, #357abd, #2868a5);
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.message {
  padding: 1rem;
  border-radius: 6px;
  margin-top: 1rem;
  text-align: center;
  font-weight: 500;
}

.success-message {
  background: linear-gradient(135deg, rgba(74, 144, 226, 0.1), rgba(53, 122, 189, 0.1));
  border: 1px solid rgba(74, 144, 226, 0.3);
  color: #4a90e2;
}

.error-message {
  background: linear-gradient(135deg, rgba(255, 68, 68, 0.1), rgba(255, 68, 68, 0.05));
  border: 1px solid rgba(255, 68, 68, 0.3);
  color: #ff4444;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.icon-spin {
  animation: spin 1s linear infinite;
}

.patient-actions {
  display: flex;
  gap: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.edit-button, .delete-button {
  flex: 1;
  min-width: 120px;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 500;
  transition: all 0.2s ease;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.edit-button {
  background: linear-gradient(135deg, #4CAF50, #45a049);
  color: white;
}

.edit-button:hover {
  background: linear-gradient(135deg, #45a049, #3d8b40);
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.delete-button {
  background: linear-gradient(135deg, #dc3545, #c82333);
  color: white;
}

.delete-button:hover {
  background: linear-gradient(135deg, #c82333, #bd2130);
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.delete-confirm {
  margin-top: 15px;
  padding: 20px;
  border-radius: 8px;
  background: #2a2a2a;
  border: 1px solid rgba(220, 53, 69, 0.3);
}

.delete-confirm p {
  margin: 0 0 20px 0;
  color: #ff6b6b;
  font-weight: 500;
  text-align: center;
}

.confirm-buttons {
  display: flex;
  gap: 12px;
  width: 100%;
}

.confirm-yes, .confirm-no {
  flex: 1;
  min-width: 120px;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 500;
  transition: all 0.2s ease;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.confirm-yes {
  background: linear-gradient(135deg, #dc3545, #c82333);
  color: white;
}

.confirm-yes:hover:not(:disabled) {
  background: linear-gradient(135deg, #c82333, #bd2130);
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.confirm-yes:disabled {
  background: linear-gradient(135deg, #6c757d, #5a6268);
  cursor: not-allowed;
  transform: none;
  opacity: 0.7;
}

.confirm-no {
  background: linear-gradient(135deg, #6c757d, #5a6268);
  color: white;
}

.confirm-no:hover {
  background: linear-gradient(135deg, #5a6268, #4e555b);
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.patient-info .form-group {
  margin-bottom: 16px;
}

.patient-info input,
.patient-info select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.patient-info input:focus,
.patient-info select:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.patient-info .readonly-field {
  padding: 8px;
  background: #2a2a2a;
  border: 1px solid #444;
  border-radius: 4px;
  color: #999;
  margin: 0;
}

.patient-bookings-section {
  margin-top: 2rem;
  padding: 1rem;
  background-color: rgba(42, 42, 42, 0.6);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.patient-bookings-section h4 {
  margin-bottom: 1rem;
  color: #4a90e2;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: 2px solid rgba(74, 144, 226, 0.3);
  padding-bottom: 0.5rem;
}

.bookings-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.booking-item {
  background-color: #2a2a2a;
  padding: 1rem;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  gap: 1rem;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.booking-item.completed {
  background-color: rgba(76, 175, 80, 0.2);
  border: 1px solid rgba(76, 175, 80, 0.3);
}

.booking-date {
  font-weight: 500;
  color: #e0e0e0;
}

.booking-time {
  color: #bbbbbb;
}

.booking-procedures {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.procedure-name {
  background-color: rgba(74, 144, 226, 0.2);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #4a90e2;
  border: 1px solid rgba(74, 144, 226, 0.3);
}

.booking-status {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  color: #e0e0e0;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.pagination-controls button {
  padding: 0.5rem 1rem;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #333;
  color: #e0e0e0;
  cursor: pointer;
  transition: all 0.2s;
}

.pagination-controls button:hover:not(:disabled) {
  background-color: #444;
  transform: translateY(-1px);
}

.pagination-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-controls span {
  color: #bbbbbb;
}

.loading-indicator {
  text-align: center;
  padding: 1rem;
  color: #bbbbbb;
} 
body {
  /* font-family: Arial, sans-serif; */
  background-color: black;
  color: white;
  margin: 0;
  padding: 0;
}

button {
  font-family: inherit;
}

input {
  font-family: inherit;
}

select {
  font-family: inherit;
}

textarea {
  font-family: inherit;
}

.App {
  display: flex;
  height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

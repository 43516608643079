.sidebar {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: calc(100vh - 48px); /* Subtract header height */
    background-color: #212121;
    padding: 0;
    transition: transform 0.3s ease;
    position: relative;
    overflow: hidden;
}

.sidebar-toggle {
    display: none;
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 1100;
    background: none;
    border: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
}

.sidebar-item {
    display: flex;
    align-items: center;
    padding: 10px 24px;
    color: white;
    text-decoration: none;
    transition: background-color 0.2s;
}

.sidebar-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-icon {
    font-size: 20px;
    margin-right: 24px;
}

.sidebar-nav-items {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    padding-bottom: 60px; /* Make room for the fixed sign-out button */
    padding-top: 12px;
}

.sidebar-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.signout-button {
    background: none;
    border: none;
    cursor: pointer;
    width: 100%;
    padding: 15px;
    color: #ff4444;
    display: flex;
    align-items: center;
    text-align: left;
    position: absolute; /* Changed from fixed to absolute */
    bottom: 0;
    background-color: #212121;
    box-shadow: 0 -4px 6px -2px rgba(0, 0, 0, 0.1);
    width: 250px;
    z-index: 10;
}

.signout-button:hover {
    background-color: rgba(255, 68, 68, 0.1);
}

.tenant-info {
    padding: 15px;
    text-align: center;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.tenant-info h3 {
    margin: 0;
    color: #b0b0b0;
    font-size: 14px;
    font-weight: 400;
    white-space: normal;
    word-wrap: break-word;
}

@media (max-width: 768px) {
    .sidebar {
        position: fixed;
        top: 48px; /* Match header height */
        left: 0;
        z-index: 1050;
        transform: translateX(-100%);
        transition: transform 0.3s ease;
        padding-top: 12px;
        height: calc(100vh - 48px); /* Subtract header height */
    }
    
    .signout-button {
        width: 250px;
    }

    .sidebar.open {
        transform: translateX(0);
    }

    .sidebar-toggle {
        display: block;
    }
}
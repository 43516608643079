@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.app-header {
  background-color: #2E3033;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  padding: 0.5rem 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding-left: 30px;
  height: 100%;
}

.header-logo {
  height: 32px;
  width: auto;
}

.header-title {
  /* font-family: 'Poppins', sans-serif; */
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
  letter-spacing: 0.5px;
} 
.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #2E3033;
  overflow: hidden; /* Prevent outer scrolling */
  font-family: 'Roboto Flex', sans-serif;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent body scrolling */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  flex: 1;
  padding: 20px;
  background-color: #f5f5f5;
  overflow-y: auto; /* Only this container should scroll */
  height: calc(100vh - 48px); /* Subtract header height */
}

button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #b0b0b0;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #8a8a8a;
}

.card {
  display: flex;
  flex: 1;
  margin-top: 48px; /* Exact height of header */
  width: 100%;
  height: calc(100vh - 48px); /* Subtract header height */
  background-color: #2E3033;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Prevent card scrolling */
}

.agenda {
  width: 100%;  /* or any percentage or value you wish */
  flex-grow: 1;  /* This will allow the agenda to take up any remaining space */
}
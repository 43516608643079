body.config-page {
  background-color: #111;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

.config-container {
  max-width: 800px;
  height: calc(100vh - 40px);
  margin: 0 auto;
  padding: 20px;
  background-color: #222;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #f0f0f0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.config-container h2 {
  color: #f0f0f0;
  border-bottom: 2px solid #444;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.config-section {
  background-color: #333;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.config-section h3 {
  color: #f0f0f0;
  margin-bottom: 10px;
}

.cache-reset-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.cache-reset-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cache-reset-button:hover {
  background-color: #d32f2f;
}

.cache-reset-notification {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border-radius: 4px;
  animation: fadeIn 0.5s;
}

.config-description {
  color: #bbb;
  font-size: 0.9em;
  margin-top: 10px;
}

/* Tab styling */
.tabs-container {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #444;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px 4px 0 0;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  color: #bbb;
}

.tab:hover {
  background-color: #333;
}

.tab.active {
  background-color: #333;
  border: 1px solid #444;
  border-bottom-color: #333;
  margin-bottom: -1px;
  font-weight: bold;
  color: #fff;
}

.tab-content {
  flex: 1;
  overflow: auto;
}

/* Procedures editor styling */
.procedures-editor {
  background-color: #333;
  border-radius: 6px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.procedures-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #444;
  position: sticky;
  top: 0;
  background-color: #333;
  z-index: 10;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.procedures-header:hover {
  background-color: #363636;
}

.search-container {
  flex: 1;
  max-width: 300px;
}

.search-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #555;
  border-radius: 4px;
  font-size: 14px;
  background-color: #222;
  color: #fff;
}

.search-input:focus {
  outline: none;
  border-color: #0b7dda;
  box-shadow: 0 0 0 2px rgba(11, 125, 218, 0.25);
}

.procedures-actions {
  display: flex;
  gap: 10px;
}

.procedures-list {
  overflow-y: auto;
  padding-right: 5px;
  flex: 1;
}

.action-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-button {
  background-color: #4CAF50;
  color: white;
}

.add-button:hover {
  background-color: #45a049;
}

.save-button {
  background-color: #2196F3;
  color: white;
}

.save-button:hover {
  background-color: #0b7dda;
}

.save-button:disabled {
  background-color: #2c5d8c;
  cursor: not-allowed;
  color: #aaa;
}

.remove-button {
  background-color: #f44336;
  color: white;
}

.remove-button:hover {
  background-color: #d32f2f;
}

.remove-button.small {
  padding: 4px 8px;
  font-size: 12px;
}

.subcategory-button {
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 8px 14px;
  transition: all 0.2s ease;
  margin-left: 8px;
}

.subcategory-button:hover {
  background-color: #45a049;
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

.subcategory-button:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.status-message {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  animation: fadeIn 0.5s;
}

.status-message.success {
  background-color: rgba(0, 128, 0, 0.2);
  color: #7bdb7b;
  border: 1px solid rgba(0, 128, 0, 0.3);
}

.status-message.error {
  background-color: rgba(128, 0, 0, 0.2);
  color: #ff7777;
  border: 1px solid rgba(128, 0, 0, 0.3);
}

.loading-message {
  padding: 20px;
  text-align: center;
  color: #bbb;
}

.procedure-item {
  border: 1px solid #444;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
}

.procedure-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  background-color: #2a2a2a;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-left: 3px solid transparent;
}

.procedure-header:hover {
  background-color: #363636;
  border-left-color: #4CAF50;
}

.procedure-header[data-has-subcategories]:not([data-has-subcategories=""]) {
  border-left-color: #4CAF50;
}

.procedure-name {
  margin: 0;
  font-size: 16px;
  flex: 1;
  color: #f0f0f0;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.dropdown-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  background-color: #444;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 12px;
  color: #fff;
  transition: all 0.2s ease;
}

.procedure-item:hover .dropdown-icon {
  background-color: #4CAF50;
}

/* Add an indicator to show which procedures have subcategories */
.procedure-header[data-has-subcategories]:not([data-has-subcategories=""]):after {
  content: attr(data-has-subcategories);
  display: inline-block;
  margin-left: 10px;
  font-size: 12px;
  background-color: #4CAF50;
  color: white;
  padding: 3px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.procedure-actions {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  z-index: 3;
}

.procedure-actions input, 
.procedure-actions button {
  pointer-events: auto;
}

.value-editor {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #f0f0f0;
}

.value-input {
  width: 80px;
  padding: 4px 8px;
  border: 1px solid #555;
  border-radius: 4px;
  background-color: #222;
  color: #fff;
  position: relative;
  z-index: 2;
}

.value-input:focus {
  outline: none;
  border-color: #0b7dda;
  box-shadow: 0 0 0 2px rgba(11, 125, 218, 0.25);
}

.subcategories-container {
  padding: 15px;
  border-top: 1px solid #444;
  background-color: #2c2c2c;
}

.subcategories-container h4 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #bbb;
}

.subcategories-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.subcategory-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: #3a3a3a;
  border-radius: 4px;
}

.subcategory-name {
  font-size: 14px;
  color: #f0f0f0;
}

.subcategory-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Scrollbar styling */
.procedures-list::-webkit-scrollbar {
  width: 8px;
}

.procedures-list::-webkit-scrollbar-track {
  background: #2a2a2a;
  border-radius: 4px;
}

.procedures-list::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 4px;
}

.procedures-list::-webkit-scrollbar-thumb:hover {
  background: #777;
}

/* For Firefox */
.procedures-list {
  scrollbar-width: thin;
  scrollbar-color: #555 #2a2a2a;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.button-icon {
  display: inline-block;
  margin-right: 5px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
} 
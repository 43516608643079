.booking-container {
  width: 100%;
  height: 100vh;
  padding: 2rem;
  padding-bottom: 4rem;
  background: #1a1a1a;
  overflow-y: auto;
  box-sizing: border-box;
}

.booking-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2rem;
  margin-top: 1rem;
}

.booking-section {
  background: linear-gradient(145deg, #222222, #2d2d2d);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.booking-section h3 {
  color: #4a90e2;
  margin: 0 0 1.5rem 0;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: 2px solid rgba(74, 144, 226, 0.3);
  padding-bottom: 0.5rem;
}

.professional-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.professional-card {
  background: #444;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}

.professional-card:hover {
  transform: scale(1.05);
  background: #4a90e2;
}

.professional-card.selected {
  background: #4a90e2;
  border: 2px solid white;
}

.procedure-search {
  margin-bottom: 15px;
}

.procedure-search-input {
  width: 100%;
  padding: 12px;
  background: #444;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
}

.procedure-search-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.procedure-list {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background: #2a2a2a;
}

.procedure-item {
  padding: 12px 15px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: background 0.2s ease;
  color: white;
}

.procedure-item:hover {
  background: #4a90e2;
}

.procedure-item:last-child {
  border-bottom: none;
}

.subprocedure-list {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background: #2a2a2a;
}

.subprocedure-header {
  padding: 12px 15px;
  background: #333;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
}

.subprocedure-header button {
  padding: 8px 12px;
  border: none;
  background: #4a90e2;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.subprocedure-header button:hover {
  background: #357abd;
}

.subprocedure-item {
  padding: 12px 15px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: background 0.2s ease;
  color: white;
}

.subprocedure-item:hover {
  background: #4a90e2;
}

.selected-procedures {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.selected-procedures h4 {
  color: #4a90e2;
  margin-bottom: 12px;
}

.selected-procedure-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 12px 15px;
  background: linear-gradient(145deg, #444444, #3d3d3d);
  border-radius: 8px;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-left: 4px solid #4a90e2;
}

.procedure-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.procedure-name {
  font-weight: 500;
  margin-bottom: 8px;
  color: #e0e0e0;
}

.subprocedure-name {
  color: #4a90e2;
  font-style: italic;
}

.procedure-value-edit {
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
}

.procedure-value-edit label {
  font-size: 0.85rem;
  color: #999;
  width: 65px;
}

.procedure-value-edit input {
  background: #333;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px 10px;
  border-radius: 4px;
  flex-grow: 1;
  max-width: 120px;
  font-size: 0.9rem;
}

.procedure-value-edit input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.procedure-actions {
  display: flex;
  gap: 5px;
  margin-left: auto;
}

.discount-button, .remove-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  font-size: 1rem;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.discount-button {
  background-color: #4a90e2;
  color: white;
}

.discount-button:hover {
  background-color: #357abd;
  transform: scale(1.05);
}

.remove-button {
  background-color: #e74c3c;
  color: white;
  font-size: 1.2rem;
}

.remove-button:hover {
  background-color: #c0392b;
  transform: scale(1.05);
}

.discount-overlay {
  position: absolute;
  top: 38px;
  right: 45px;
  background-color: #333;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 10;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  min-width: 70px;
}

.discount-overlay-header {
  color: #4a90e2;
  font-weight: 500;
  font-size: 0.9rem;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}

.discount-overlay:before {
  content: '';
  position: absolute;
  top: -6px;
  right: 15px;
  width: 12px;
  height: 12px;
  background: #333;
  transform: rotate(45deg);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.discount-overlay button {
  background-color: #444;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: background-color 0.2s;
  text-align: center;
}

.discount-overlay button:hover {
  background-color: #4a90e2;
}

.time-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.time-card {
  background: #444;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}

.time-card:hover {
  transform: scale(1.05);
  background: #4a90e2;
}

.time-card.selected {
  background: #4a90e2;
  border: 2px solid white;
}

.date-input {
  width: 100%;
  padding: 12px;
  background: #444;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
}

.date-input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.book-button {
  width: 100%;
  padding: 0.75rem 1.75rem;
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background: linear-gradient(135deg, #4a90e2, #357abd);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.book-button:hover:not(:disabled) {
  background: linear-gradient(135deg, #357abd, #2868a5);
  transform: translateY(-1px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.book-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.date-time-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.date-time-container .time-grid {
  margin-top: 10px;
}

.date-time-container .duration-input {
  margin-top: 10px;
}

.time-selection-container {
  display: flex;
  gap: 15px;
  width: 100%;
  justify-content: center;
}

.time-column {
  width: 120px;
  background: #444;
  border-radius: 8px;
  padding: 10px;
}

.time-column h4 {
  color: #4a90e2;
  text-align: center;
  margin-bottom: 10px;
  border-bottom: 2px solid rgba(74, 144, 226, 0.3);
  padding-bottom: 5px;
}

.time-scroll {
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4a90e2 #444;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-scroll::-webkit-scrollbar {
  width: 8px;
}

.time-scroll::-webkit-scrollbar-track {
  background: #444;
}

.time-scroll::-webkit-scrollbar-thumb {
  background-color: #4a90e2;
  border-radius: 4px;
}

.time-item {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}

.time-item:hover {
  background: rgba(74, 144, 226, 0.3);
}

.time-item.selected {
  background: #4a90e2;
  color: white;
  font-weight: bold;
}

.success-message {
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
}

.success-message.success {
  background-color: rgba(76, 175, 80, 0.1);
  color: #4CAF50;
}

.success-message.error {
  background-color: rgba(244, 67, 54, 0.1);
  color: #F44336;
}

.booking-tabs {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.tab-button {
  flex: 1;
  padding: 10px 15px;
  background-color: #222222;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
}

.tab-button.active {
  background-color: #2d2d2d;
  border-bottom-color: #4a90e2;
  color: #4a90e2;
}

.tab-button:hover {
  background-color: #2a2a2a;
  color: rgba(255, 255, 255, 0.8);
}

.bookings-list {
  width: 100%;
  overflow-x: auto;
}

.bookings-list table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #1e1e1e;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  color: #e0e0e0;
}

.bookings-list th, 
.bookings-list td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #333;
}

.bookings-list th {
  background-color: #2a2a2a;
  font-weight: 600;
  color: #e0e0e0;
}

.bookings-list tr:nth-child(even) {
  background-color: #f9f9f9;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.bookings-search-container {
  width: 100%;
  background: linear-gradient(145deg, #222222, #2d2d2d);
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.bookings-search-filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto;
  gap: 15px;
  margin-bottom: 20px;
  align-items: flex-end;
}

.bookings-search-filters > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.bookings-search-filters label {
  color: #4a90e2;
  font-weight: 500;
  margin-bottom: 5px;
}

.bookings-search-filters select,
.bookings-search-filters .date-input {
  width: 100%;
  padding: 10px;
  background-color: #444;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
}

.search-button {
  height: 42px;
  padding: 0 15px;
  background: linear-gradient(135deg, #4a90e2, #357abd);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.search-button:hover {
  background: linear-gradient(135deg, #357abd, #2868a5);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.bookings-list table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  background-color: #2a2a2a;
}

.bookings-list th, 
.bookings-list td {
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 12px;
  text-align: left;
  color: white;
}

.bookings-list th {
  background-color: #333;
  font-weight: 600;
  color: #4a90e2;
}

.bookings-list tr:nth-child(even) {
  background-color: rgba(74, 144, 226, 0.05);
}

.bookings-list tr:hover {
  background-color: rgba(74, 144, 226, 0.1);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #2a2a2a;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h3 {
  margin-top: 0;
  color: white;
}

.modal-content p {
  margin: 10px 0;
  color: #e0e0e0;
}

.modal-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.modal-content li {
  padding: 5px 0;
  color: #ff6b6b;
  font-weight: 500;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
}

.close-button:hover {
  color: #333;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group input[type="datetime-local"],
.form-group input[type="number"],
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.procedure-edit-item {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.procedure-edit-item input[type="text"] {
  flex: 2;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.procedure-edit-item input[type="number"] {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.modal-actions {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: flex-end;
}

.action-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.action-button.success {
  background-color: #4CAF50;
  color: white;
}

.action-button.success:hover {
  background-color: #45a049;
}

.action-button.danger {
  background-color: #f44336;
  color: white;
}

.action-button.danger:hover {
  background-color: #da190b;
}

.action-button.secondary {
  background-color: #f5f5f5;
  color: #333;
}

.action-button.secondary:hover {
  background-color: #e0e0e0;
}

.modal-content.dark {
  background: linear-gradient(145deg, #222222, #2d2d2d);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.modal-content.dark h2 {
  color: #4a90e2;
  border-bottom: 2px solid rgba(74, 144, 226, 0.3);
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
}

.modal-content.dark .form-group label {
  color: #4a90e2;
}

.dark-input {
  background: #444 !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.dark-input:focus {
  outline: none;
  border-color: #4a90e2 !important;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.dark-input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.modal-content.dark .action-button {
  background: #444;
  color: white;
}

.modal-content.dark .action-button:hover {
  background: #555;
}

.modal-content.dark .action-button.success {
  background: #4CAF50;
}

.modal-content.dark .action-button.success:hover {
  background: #45a049;
}

.modal-content.dark .action-button.danger {
  background: #f44336;
}

.modal-content.dark .action-button.danger:hover {
  background: #da190b;
}

.modal-content.dark .action-button.secondary {
  background: #333;
  color: #fff;
}

.modal-content.dark .action-button.secondary:hover {
  background: #444;
}

.google-calendar-banner {
  background: linear-gradient(145deg, #2d2d2d, #383838);
  padding: 15px 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(74, 144, 226, 0.3);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}

.google-calendar-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, #4a90e2, #357abd, #4a90e2);
  animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
}

.google-calendar-banner p {
  margin: 0;
  color: #ffffff;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.google-calendar-button {
  background: linear-gradient(135deg, #4285f4, #357abd);
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.google-calendar-button:hover {
  background: linear-gradient(135deg, #357abd, #2868a5);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.search-status {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.status-checkboxes {
  display: flex;
  gap: 16px;
  align-items: center;
}

.status-checkbox {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  user-select: none;
}

.status-checkbox input[type="checkbox"] {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

/* Patient's last bookings styles */
.patient-last-bookings {
  margin-top: 15px;
  background: #2a2a2a;
  border-radius: 8px;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.patient-last-bookings h4 {
  color: #4a90e2;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 14px;
}

.last-bookings-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.last-booking-item {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding: 8px 10px;
  background: #333;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-left: 3px solid #4a90e2;
}

.last-booking-item:hover {
  background: #3a3a3a;
  transform: translateX(2px);
}

.last-booking-procedure {
  font-size: 13px;
  color: #e0e0e0;
}

.last-booking-procedure:not(:last-child)::after {
  content: ",";
  margin-right: 4px;
}

.last-booking-date {
  font-size: 12px;
  color: #888;
  margin-left: auto;
}

.loading-indicator {
  color: #ccc;
  text-align: center;
  padding: 1rem;
}

.discount-buttons {
  display: flex;
  gap: 5px;
  margin-top: 5px;
}

.discount-buttons button {
  background-color: #e0e0e0;
  border: none;
  border-radius: 4px;
  padding: 2px 5px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.discount-buttons button:hover {
  background-color: #ccc;
}

.discount-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 8px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.discount-button:hover {
  background-color: #357abd;
}

.discount-overlay {
  position: absolute;
  background-color: #333;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 10;
  margin-top: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.discount-overlay button {
  background-color: #444;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: background-color 0.2s;
  text-align: center;
}

.discount-overlay button:hover {
  background-color: #555;
}

.procedure-value-edit {
  display: flex;
  align-items: center;
  margin-top: 5px;
  position: relative;
}

/* Calendar View Styles */
.view-toggle-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  justify-content: center;
}

.view-toggle-button {
  padding: 8px 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #333;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.view-toggle-button.active {
  background-color: #1976d2;
  color: white;
  border-color: #1976d2;
}

.view-toggle-button:hover:not(.active) {
  background-color: #444;
}

.calendar-view {
  width: 100%;
  overflow-x: auto;
}

.calendar-days {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.calendar-day {
  background-color: #2a2a2a;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.calendar-date-header {
  margin: 0 0 15px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #444;
  font-size: 1.2rem;
  color: #4a90e2;
}

.calendar-professionals {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.calendar-professional {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  margin-bottom: 30px;
}

.professional-name {
  margin: 0 0 10px 0;
  color: #e0e0e0;
  font-size: 1.1rem;
}

.time-grid-container {
  display: flex;
  position: relative;
  height: 650px;
  min-height: calc(40px * var(--total-hours, 12));
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #222;
  overflow: visible;
}

.time-markers {
  width: 60px;
  position: relative;
  border-right: 1px solid #444;
  background-color: #333;
  min-height: 100%;
  height: 100%;
}

.time-marker {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  color: #aaa;
  height: 20px;
  line-height: 20px;
}

.appointment-grid {
  flex: 1;
  position: relative;
  background: linear-gradient(to bottom, transparent 49.9%, #444 50%, transparent 50.1%);
  background-size: 100% calc(100% / var(--total-hours, 12));
  min-height: 100%;
  height: 100%;
  width: 100%;
}

.appointment-block {
  position: absolute;
  width: calc(100% - 10px);
  left: 5px;
  border-radius: 4px;
  color: white;
  padding: 5px;
  cursor: pointer;
  overflow: hidden;
  transition: opacity 0.2s;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 1;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.2);
  max-height: none !important;
}

.appointment-block:hover {
  opacity: 0.9;
  z-index: 2;
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.appointment-content {
  font-size: 0.85rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 2px;
  line-height: 1.2;
}

/* Remove these rules since we no longer have these elements */
.appointment-time,
.appointment-procedures,
.appointment-procedure {
  display: none;
}

/* For smaller screens */
@media (max-width: 768px) {
  .time-grid-container {
    height: 500px;
  }
  
  .appointment-content {
    font-size: 0.75rem;
  }
  
  .time-marker {
    font-size: 0.7rem;
  }
}

.room-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.room-card {
  background: #444;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}

.room-card:hover {
  transform: scale(1.05);
  background: #4a90e2;
}

.room-card.selected {
  background: #4a90e2;
  border: 2px solid white;
}

.room-card h4 {
  margin: 0 0 0.5rem 0;
  color: white;
}

.room-card p {
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
}

.room-bookings {
  margin-top: 1.5rem;
  background: #333;
  border-radius: 8px;
  padding: 1rem;
}

.room-bookings h4 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: white;
  border-bottom: 1px solid #555;
  padding-bottom: 0.5rem;
}

.room-bookings-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.room-booking-item {
  display: flex;
  align-items: center;
  background: #444;
  padding: 0.75rem;
  border-radius: 4px;
  color: white;
}

.room-booking-time {
  font-weight: bold;
  margin-right: 1rem;
  padding: 0.25rem 0.5rem;
  background: #4a90e2;
  border-radius: 4px;
  min-width: 50px;
  text-align: center;
}

.room-booking-patient {
  flex: 1;
  font-weight: 500;
}

.room-booking-professional {
  color: rgba(255, 255, 255, 0.7);
  font-style: italic;
  margin-left: 1rem;
}

.loading-indicator {
  color: #ccc;
  text-align: center;
  padding: 1rem;
}

/* Time slots calendar styles */
.room-time-slots {
  background: #333;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.time-slots-header {
  display: flex;
  background: #222;
  padding: 0.5rem;
  border-bottom: 1px solid #555;
}

.time-slots-container {
  max-height: 300px;
  overflow-y: auto;
  padding: 0.5rem;
}

.hour-slots {
  margin-bottom: 0.5rem;
}

.time-slot {
  display: flex;
  padding: 0.3rem 0.5rem;
  border-radius: 4px;
  margin-bottom: 2px;
  background: #444;
}

.time-slot.occupied {
  background-color: rgba(59, 130, 246, 0.1); /* Light blue background */
}

.time-slot.simulated {
  background-color: rgba(253, 224, 71, 0.2); /* Light yellow background */
}

.time-slot.conflict {
  background-color: rgba(239, 68, 68, 0.1); /* Light red background */
}

.time-slot.selected {
  border-left: 3px solid #4a90e2;
}

.time-slot-label {
  width: 60px;
  font-weight: bold;
  color: #aaa;
}

.time-slot-content {
  flex: 1;
}

.slot-booking {
  padding: 0.1rem 0.3rem;
  background: #4a90e2;
  border-radius: 3px;
  font-size: 0.85rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: fit-content;
}

.slot-booking.existing {
  background-color: #3b82f6; /* Blue */
  color: #fff;
}

.slot-booking.simulated {
  background-color: #fbbf24; /* Yellow */
  color: #333;
}

.slot-booking.simulated.conflict {
  background-color: #ef4444; /* Red */
  color: #fff;
  font-weight: bold;
}

.conflict-warning {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #fee2e2;
  border: 1px solid #ef4444;
  border-radius: 4px;
  color: #b91c1c;
  font-size: 14px;
}

.conflict-warning p {
  margin: 0;
}

.room-bookings-list h5 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: white;
  border-bottom: 1px solid #555;
  padding-bottom: 0.5rem;
}

.room-booking-message {
  margin-top: 10px;
  padding: 10px;
  background-color: #f8f9fa;
  border-left: 4px solid #17a2b8;
  border-radius: 4px;
  color: #495057;
  font-size: 14px;
}

.room-booking-message p {
  margin: 0;
}
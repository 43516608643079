.financeiro-container {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  background-color: #1a1a1a;
  color: #e0e0e0;
}

.financeiro-tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: #999;
  transition: all 0.3s ease;
  font-family: inherit;
}

.tab-button.active {
  color: #4a90e2;
  border-bottom: 2px solid #4a90e2;
}

.caixa-container {
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.caixa-search-filters {
  margin-bottom: 20px;
}

.date-filters {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
}

.quick-filters {
  display: flex;
  gap: 10px;
}

.quick-filter-button {
  padding: 8px 16px;
  border: 1px solid #333;
  border-radius: 4px;
  background: #2a2a2a;
  color: #e0e0e0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.quick-filter-button:hover {
  background: #333;
}

.date-input-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.date-input-group label {
  font-weight: 500;
  color: #999;
}

.date-input {
  padding: 8px;
  border: 1px solid #333;
  border-radius: 4px;
  font-size: 14px;
  background-color: #2a2a2a;
  color: #e0e0e0;
}

.status-select {
  padding: 8px;
  border: 1px solid #333;
  border-radius: 4px;
  font-size: 14px;
  background-color: #2a2a2a;
  color: #e0e0e0;
}

.dark-button {
  padding: 8px 16px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dark-button:hover {
  background-color: #357abd;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.bookings-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.professional-group {
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.professional-group h3 {
  margin-bottom: 15px;
  color: #e0e0e0;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background-color: #1e1e1e;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #333;
  color: #e0e0e0;
}

th {
  background-color: #2a2a2a;
  font-weight: 500;
  color: #e0e0e0;
}

tr:hover {
  background-color: #2a2a2a;
}

.total-row {
  background-color: #2a2a2a;
  font-weight: 500;
}

.grand-total {
  background-color: #2a2a2a;
  color: #e0e0e0;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  border: 1px solid #333;
}

.total-value {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 18px;
}

.total-value.editable {
  cursor: pointer;
  transition: all 0.2s;
}

.total-value.editable:hover {
  color: #007bff;
}

.amount-input {
  width: 120px;
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

.button-group {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  color: #e0e0e0;
  border: 1px solid #333;
}

.modal-content.dark {
  background-color: #1e1e1e;
  color: #e0e0e0;
}

.booking-details {
  display: grid;
  gap: 15px;
  margin: 20px 0;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-weight: 500;
  color: #999;
}

.detail-value {
  font-size: 16px;
  background-color: #2a2a2a;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #333;
}

.detail-value.editable {
  display: flex;
  align-items: center;
}

.commission-input {
  width: 120px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: #fff;
  color: #333;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.action-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #4a90e2;
  color: white;
}

.action-button:hover {
  background-color: #357abd;
}

.action-button.update {
  background-color: #4CAF50;
  margin-right: 10px;
}

.action-button.update:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagamentos-container {
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #e0e0e0;
}

.commission-list {
  padding: 20px;
  max-height: 60vh;
  overflow-y: auto;
}

.commission-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #444;
  margin-bottom: 10px;
}

.professional-name {
  font-size: 1.1em;
  font-weight: bold;
}

.commission-input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.commission-input input {
  width: 120px;
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  background: #333;
  color: white;
}

.date-selection {
  margin-bottom: 20px;
  padding: 15px;
  background: #2a2a2a;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.date-selection label {
  font-weight: 500;
  color: #999;
  min-width: 140px;
}

.date-selection .date-input {
  flex: 1;
  max-width: 200px;
}

.installments-table {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.installment-row {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.installment-row input {
  margin: 0;
}

/* Required field indicator */
.required {
    color: #ff4444;
    margin-left: 4px;
}

/* Custom checkbox */
.checkbox-group {
    margin: 15px 0;
}

.checkbox-container {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #2a2a2a;
    border: 2px solid #4a90e2;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.checkbox-container:hover input ~ .checkmark {
    background-color: #333;
}

.checkbox-container input:checked ~ .checkmark {
    background-color: #4a90e2;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Alert message */
.alert-message {
    background-color: rgba(255, 68, 68, 0.1);
    border: 1px solid #ff4444;
    color: #ff4444;
    padding: 12px;
    border-radius: 4px;
    margin: 10px 0;
    font-size: 14px;
}

/* Improve installments table */
.installments-table {
    background-color: #2a2a2a;
    padding: 15px;
    border-radius: 8px;
    margin: 15px 0;
}

.installment-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #1e1e1e;
    border-radius: 4px;
}

.installment-row input {
    width: 100%;
    padding: 8px;
    border: 1px solid #444;
    border-radius: 4px;
    background-color: #2a2a2a;
    color: #e0e0e0;
    font-size: 14px;
}

.installment-row input:focus {
    border-color: #4a90e2;
    outline: none;
}

.installments-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #444;
    font-weight: 500;
}

.installments-total .total-amount {
    color: #4a90e2;
    font-size: 16px;
}

.file-upload-wrapper {
    margin-top: 8px;
}

.file-upload-label {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    background-color: #2c2c2c;
    border: 1px solid #444;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
}

.file-upload-label:hover {
    background-color: #3c3c3c;
}

.report-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.report-button:hover {
  background-color: #357abd;
}

.report-button svg {
  font-size: 16px;
}

.payment-modal {
    background-color: #1e1e1e;
    border-radius: 12px;
    max-width: 600px;
    width: 90%;
    border: 1px solid #333;
}

.payment-modal .modal-header {
    background-color: #2a2a2a;
    padding: 20px;
    border-radius: 12px 12px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #333;
}

.payment-modal .modal-header h2 {
    margin: 0;
    color: #e0e0e0;
    font-size: 1.5rem;
}

.payment-modal .close-button {
    background: none;
    border: none;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    padding: 0 8px;
}

.payment-modal .close-button:hover {
    color: #e0e0e0;
}

.payment-modal .modal-body {
    padding: 20px;
}

.payment-modal .details-row {
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
}

.payment-modal .details-group {
    flex: 1;
}

.payment-modal .details-group.full-width {
    flex: 1 1 100%;
}

.payment-modal label {
    display: block;
    color: #999;
    margin-bottom: 8px;
    font-size: 0.9rem;
}

.payment-modal .reference-code {
    color: #4a90e2;
    background: rgba(74, 144, 226, 0.1);
    padding: 4px 8px;
    border-radius: 4px;
}

.payment-modal .date-field {
    color: #e0e0e0;
}

.payment-modal .supplier-name {
    color: #e0e0e0;
    font-weight: 500;
}

.payment-modal .status-badge {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 0.9rem;
    text-transform: uppercase;
}

.payment-modal .status-badge.sent {
    background-color: #2196F3;
    color: white;
}

.payment-modal .status-badge.paid {
    background-color: #4CAF50;
    color: white;
}

.payment-modal .amount-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.payment-modal .success-message {
    color: #4CAF50;
    font-size: 0.9rem;
    position: absolute;
    bottom: -24px;
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.payment-modal .save-button:disabled {
    background-color: #666;
    cursor: not-allowed;
}

.payment-modal .amount-input:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.payment-modal .amount-display {
    font-size: 1.2rem;
    color: #4CAF50;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
}

.payment-modal .amount-display.editable {
    cursor: pointer;
}

.payment-modal .amount-display.editable:hover {
    color: #45a049;
}

.payment-modal .edit-icon {
    font-size: 0.9rem;
    opacity: 0.7;
}

.payment-modal .amount-input {
    background-color: #2a2a2a;
    border: 1px solid #444;
    color: #e0e0e0;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 1.1rem;
    width: 150px;
}

.payment-modal .amount-input:focus {
    border-color: #4a90e2;
    outline: none;
}

.payment-modal .save-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s;
}

.payment-modal .save-button:hover {
    background-color: #45a049;
}

.payment-modal .description-text,
.payment-modal .notes-text {
    color: #e0e0e0;
    line-height: 1.4;
}

.payment-modal .download-button {
    background-color: #4a90e2;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s;
}

.payment-modal .download-button:hover {
    background-color: #357abd;
}

.payment-modal .mark-paid-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
    transition: background-color 0.2s;
    margin-top: 20px;
}

.payment-modal .mark-paid-button:hover {
    background-color: #45a049;
}

.payment-modal .file-actions {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
}

.payment-modal .file-upload-section {
    display: flex;
    gap: 12px;
    align-items: center;
}

.payment-modal .file-upload-wrapper {
    flex: 1;
}

.payment-modal .file-upload-label {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    background-color: #2c2c2c;
    border: 1px solid #444;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s;
    color: #e0e0e0;
}

.payment-modal .file-upload-label:hover {
    background-color: #3c3c3c;
    border-color: #666;
}

.payment-modal .file-upload-label svg {
    font-size: 1.1rem;
    color: #4a90e2;
}

.description-edit-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.description-input {
    width: 100%;
    min-height: 80px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 10px;
    resize: vertical;
}

.description-edit-container .save-button {
    align-self: flex-end;
} 